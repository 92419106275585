import { useEffect, useState } from 'react'
import Topic from 'components/Topic'
import ErrorMessage from 'components/ErrorMessage'
import TopicMetaTags from 'components/Topic/MetaTags'

import Loader from 'components/Loader'
import ErrorPage from 'components/ErrorPage'
import { GoogleAnalytics } from 'components/GA'
import { TOPIC_SLUG_QUERY } from 'graphql/queries/TopicBySlugQuery'
import { TopicBySlug } from 'types/generated/TopicBySlug'
import { withUser } from 'lib/state/initialize'
import { useTopicPageQuery } from 'hooks/useTopicPageQuery'

export const getServerSideProps = withUser(({ query, context }) => {
  const { params } = context
  if (!params) {
    return { props: {} }
  }
  const { topicSlug } = params

  return query({
    queries: [[TOPIC_SLUG_QUERY, { slug: topicSlug }]],
  })
})

export default function TopicPage() {
  const [{ hasNextPage, endCursor }, setPageInfo] = useState<{
    hasNextPage: boolean
    endCursor: string | null
  }>({ hasNextPage: false, endCursor: null })

  const { error, data, fetchMore, loading } = useTopicPageQuery()

  useEffect(() => {
    if (data && data.topic) {
      const {
        topic: {
          combinedContentConnection: { pageInfo },
        },
      } = data
      setPageInfo(pageInfo)
    }
  }, [data])

  if (error) {
    return <ErrorMessage message="Error loading page." />
  }

  if (!data && loading) {
    return <Loader isFull />
  }

  if (!data || !data.topic) {
    return <ErrorPage />
  }

  function onFetchedMorePosts(
    prevPosts: TopicBySlug,
    {
      fetchMoreResult,
    }: {
      fetchMoreResult?: TopicBySlug | undefined
      variables?: { after: string | null } | undefined
    }
  ): TopicBySlug {
    if (!fetchMoreResult) {
      setPageInfo({ hasNextPage: false, endCursor: null })
      return prevPosts
    }
    if (!prevPosts.topic) {
      setPageInfo({ hasNextPage: false, endCursor: null })
      return prevPosts
    }
    const {
      topic: {
        combinedContentConnection: { nodes: prevNodes },
      },
    } = prevPosts
    if (!fetchMoreResult.topic) {
      setPageInfo({ hasNextPage: false, endCursor: null })
      return prevPosts
    }
    const {
      topic,
      topic: {
        combinedContentConnection: { nodes: newNodes, pageInfo },
      },
    } = fetchMoreResult
    topic.combinedContentConnection.nodes = [...prevNodes, ...newNodes]
    setPageInfo(pageInfo)
    return { topic }
  }

  function fetchMorePosts() {
    if (fetchMore) {
      return fetchMore({
        variables: { after: endCursor },
        updateQuery: onFetchedMorePosts,
      })
    }
  }

  let genres
  let network
  let series

  switch (data.topic.__typename) {
    case 'Genre':
      genres = data.topic.name
      break
    case 'Network':
      network = data.topic.name
      break
    case 'Series':
      series = data.topic.name
      break
    default:
  }

  return (
    <>
      <GoogleAnalytics
        customDimensions={{ series, genres, network, pageType: 'Topic' }}
      />
      <TopicMetaTags topic={data.topic} />
      <Topic
        topic={data.topic}
        hasNextPage={hasNextPage}
        fetchMorePosts={fetchMorePosts}
      />
    </>
  )
}
