import style from './index.module.css'
import Image from '../../Image'
import { FollowTopicButton } from '../../FollowTopicButton'
import { ReactNode } from 'react'
import cn from 'classnames'
import { TopicBySlug_topic } from '../../../types/generated/TopicBySlug'

interface TopicHeroPayload {
  imageAlt?: string
  image?: string | null
  children?: ReactNode
  deck?: any | null
  customDimensions?: GACustomDimensions
  topic: TopicBySlug_topic
}

export default function TopicHero(props: TopicHeroPayload) {
  const { image, deck, children, imageAlt, topic } = props

  return (
    <div className={cn(style.hero)}>
      {image && (
        <Image
          className={style.heroImg}
          src={image}
          size={[414, 441]}
          sizeTabletUp={[768, 1350]}
          sizeDesktopUp={[1440, 673]}
          sizeWidescreenUp={[2000, 935]}
          alwaysDisplay={true}
          alt={imageAlt}
        />
      )}

      <div className={style.heroContents}>
        <>
          <div className={style.title}>{children}</div>
          <FollowTopicButton
            className={style.followButton}
            topicId={topic.id}
          />
          {deck && <div className={style.deck}>{deck}</div>}
        </>
      </div>
    </div>
  )
}
