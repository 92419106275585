import { TopicBySlug_topic } from 'types/generated/TopicBySlug'
import { buildImgixURL } from 'lib/images'
import { createDippUrl } from 'lib/urls'
import { StructuredData, SeoTags } from 'components/MetaTags'
import { UnstructuredPostData } from 'components/MetaTags/StructuredData/types'

const defaultImage = `https://thedipp.imgix.net/2020/09/dipp-1.jpg`

const authors = [{ name: 'Allison Piwowarski', path: '/author/allison' }]

export default function topicMetaTags({ topic }: { topic: TopicBySlug_topic }) {
  const {
    featureImage,
    name,
    description: possibleDescription,
    slug,
    updatedAt,
  } = topic
  const description =
    possibleDescription ||
    `Where you can find the latest articles about ${name}.`

  const canonicalUrl = createDippUrl(slug)
  const publishedDate = new Date(updatedAt)

  const image = {
    url: buildImgixURL(featureImage || defaultImage, { size: [1200, 630] }),
    width: 1200,
    height: 630,
  }
  // TODO this is prob wrong
  const unstructuredPost: UnstructuredPostData = {
    url: canonicalUrl,
    title: name,
    description,
    datePublished: publishedDate,
    dateUpdated: publishedDate,
    authors,
    articleSection: name,
    image,
    tags: [topic],
    isFree: true,
  }

  return (
    <>
      <SeoTags
        {...{
          url: canonicalUrl,
          title: name,
          description,
          openGraph: {
            title: name,
            description,
            url: canonicalUrl,
            type: 'article',
            image,
          },
          post: {
            datePublished: publishedDate,
            dateUpdated: publishedDate,
            authors,
          },
        }}
      />
      <StructuredData
        {...{
          post: unstructuredPost,
        }}
      />
    </>
  )
}
