import { TopicBySlug_topic } from 'types/generated/TopicBySlug'
import Hero from '../Hero/TopicHero'
import { CommunityPostInput } from 'components/CommPost/CommunityPostInput'
import { InfiniteCompoundListZone } from 'components/Zones/InfiniteCompoundListZone'
import PostClip from 'components/Clips/PostClip'
import { CommPostClip } from 'components/Clips/CommPostClip'

interface TopicProps {
  topic: TopicBySlug_topic
  hasNextPage: boolean
  fetchMorePosts: () => void
}

export default function Topic({
  topic,
  hasNextPage,
  fetchMorePosts,
}: TopicProps) {
  const {
    name,
    combinedContentConnection: { nodes },
    featureImage,
  } = topic

  return (
    <section>
      <Hero image={featureImage} imageAlt={name} topic={topic}>
        {name}
      </Hero>
      <CommunityPostInput />

      <InfiniteCompoundListZone
        length={nodes.length}
        hasMore={hasNextPage}
        fetchMore={fetchMorePosts}
      >
        {nodes.map((node) => {
          if (node.__typename === 'Post') {
            return (
              <PostClip key={node.slug} clipData={node} showAuthor isList />
            )
          }
          return <CommPostClip post={node} key={node.publicId} />
        })}
      </InfiniteCompoundListZone>
    </section>
  )
}
